import React from 'react';

const ProductListBanner = () => {
    return (
        <div className='product-list-banner'>
            <p>Liste de Produits:​</p>
        </div>
    );
};

export default ProductListBanner;
