import React from 'react';

const Button = ({ tab, setTab, buttonLabel }) => {

    return (
        <button className={tab === buttonLabel ? "buttonActive" : "button"}
            onClick={() => setTab(buttonLabel)}>
            {buttonLabel}
        </button >
    );
};

export default Button;
