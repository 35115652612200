export const options = {
    "kg": { 0: 0, 0.5: 0.5, 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7, 8: 8, 9: 9, 10: 10 },
    "unité": { 0: 0, 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7, 8: 8, 9: 9, 10: 10 },
    "gr": { 0: 0, 1: 100, 2: 200, 3: 300, 4: 400, 5: 500, 6: 600, 7: 700, 8: 800, 9: 900, 10: 1000 },
    "botte": { 0: 0, 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7, 8: 8, 9: 9, 10: 10 },
    "pièce": { 0: 0, 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7, 8: 8, 9: 9, 10: 10 },
    "paquet": { 0: 0, 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7, 8: 8, 9: 9, 10: 10 },
    "portion": { 0: 0, 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7, 8: 8, 9: 9, 10: 10 },
};

const LineItem = ({ item, quantities, setQuantities }) => {

    const colorMap = {
        "Produits Locaux": "#68A357",
        "Aromatiques Locaux": "#32965D",
        "Produit de villes voisines": "#33502B",
        "Artisanaux": "#57539A",
        "Produits importés": "#BCA371",
    }

    return (
        <li className="item">
            <section style={{ display: "flex", flexDirection: "row", width: '100%' }}>
                <div className="productPic" style={{ display: 'flex', alignItems: 'center', backgroundColor: "white" }}>
                    <img src={item.images} alt="placeholder" style={{ width: 96, height: "auto" }} />
                </div>

                <div className="productTextAndForm" style={{ display: "flex", flexDirection: "column", width: '100%', marginLeft: "0.5rem" }}>
                    <div className="productCategory">
                        <label
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                fontSize: "small",
                            }} >
                            <span style={{

                                backgroundColor: colorMap[item.categories],
                                color: "aliceblue",
                                padding: "0.1rem"

                            }}> {item.categories}</span></label>
                    </div>

                    <div className="productName" style={{ display: "flex", alignItems: "bottom" }}>
                        <label style={{ fontSize: "medium" }}>{item.name}</label>
                    </div>

                    <div className="productDescription" style={{ display: "flex", alignItems: "top", marginBottom: "1rem" }}>
                        <label style={{ fontSize: "small" }}>{item.description}</label>
                    </div>

                    <div className="form" style={{ display: "flex", alignItems: "bottom" }}>
                        <label name="unit_price" id="unit_price" className="borderLabelPrix"
                            style={{
                                fontSize: "medium",
                                marginRight: "0.25rem",
                                padding: "0.5rem"
                            }}>
                            {(item.regular_price).toFixed(2)} CHF
                        </label>

                        <select
                            className="borderLabelQuantity"
                            name="quantity"
                            id="quantity"
                            style={{
                                fontSize: "medium",
                                borderRadius: "0.25rem",
                                marginRight: "0.25rem",
                                backgroundColor: "#F3F1E2",
                                padding: "0.25rem"
                            }}
                            onChange={e => {
                                const newVale = Number(e.target.value);
                                setQuantities(prevQuantities => {
                                    if (newVale === 0) {
                                        const { [item.id]: _, ...newQuantities } = prevQuantities;
                                        return newQuantities;
                                    } else {
                                        return { ...prevQuantities, [item.id]: newVale };
                                    }

                                });

                            }}
                        >
                            {Object.entries(options[item.mesure])
                                .sort(([keyA], [keyB]) => Number(keyA) - Number(keyB))
                                .map(([key, value]) => (
                                    <option key={key} value={key}>
                                        {value} {item.mesure}
                                    </option>
                                ))}
                        </select>

                        <label name="total_price" id="total_price" className="borderLabelTotalPrix"
                            style={{
                                fontSize: "medium",
                                padding: "0.5rem"
                            }}>
                            {(quantities[item.id] ? item.regular_price * quantities[item.id] : 0).toFixed(2)} CHF
                        </label>
                    </div>

                </div>
            </section>
        </li>);
};

export default LineItem;
