import React from 'react';

const SplashAndText = () => {
    return (
        <section className='splash-container'>
            <section className="splash-image">
                <p>Les produits locaux à votre portée de main</p>
            </section>
            <p className='splash-text'>Bienvenue sur notre site web, votre destination pour des produits locaux de qualité dans la région de Romont et Fribourg. En commandant chez nous, vous économisez du temps et soutenez les producteurs et  vendeurs locaux. Découvrez des saveurs authentiques tout en favorisant notre communauté.</p>
        </section>
    );
};

export default SplashAndText;
