import Header from './Header';
import SearchItem from './searchItem';
import Content from './Content';
import Footer from './Footer';
import { useState, useEffect, useCallback, useRef } from "react";
import ButtonList from './ButtonList';
import DeliveryOptions from './DeliveryOptions';
import SplashAndText from './Splash_and_text';
import Instructions from './instructions';
import ThreeSteps from './threeSteps';
import ProductListBanner from './productListBanner';
import TotalCart from './totalCart';
import ThankYou from './thankYou';
import SpecialDemands from './SpecialDemands';

function App() {
  const PRODUCTS_URL = process.env.REACT_APP_API_IP_PRODUCTS
  const ORDERS_URL = process.env.REACT_APP_API_IP_ORDERS
  const [items, setItems] = useState([]);
  const [search, setSearch] = useState('');
  const [fetchError, setFetchError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [quantities, setQuantities] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);
  const [tab, setTab] = useState("Tous les produits");
  const [showThankYou, setShowThankYou] = useState(false);
  const [specialDemands, setSpecialDemands] = useState('');
  const [form, setForm] = useState({
    client: { first_name: '', last_name: '', phone: '', email: '', address: '', city: '', postal_code: '', state: 'Fribourg', country: 'Suisse' },
    delivery: { instruction: '', schedule: '', free_text: '' },
  });
  const formRef = useRef();
  const handleClick = () => {
    if (formRef.current) {
      const event = new Event('submit', { bubbles: true, cancelable: true });
      formRef.current.dispatchEvent(event);
    }
  };
  const calculateTotalPrice = useCallback(() => {
    const totalPrice = items.reduce((sum, item) => sum + item.regular_price * (quantities[item.id] || 0), 0);
    setTotalPrice(totalPrice);
  }, [items, quantities]);

  useEffect(() => {
    calculateTotalPrice();
  }, [calculateTotalPrice]);


  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await fetch(PRODUCTS_URL);
        if (!response.ok) throw Error('Fetch failed');
        const listItems = await response.json();
        listItems.sort((a, b) => a.position - b.position);
        setItems(listItems.filter(item => (item.published === 1)));
        setFetchError(null);
      } catch (err) {
        setFetchError(err.message);
      } finally {
        setIsLoading(false);
      }
    }
    fetchItems();
  }, [PRODUCTS_URL]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formRef.current.reportValidity()) {
      setIsSubmitting(true);
      try {
        const order = items
          .filter(item => quantities[item.id] !== undefined)
          .map(item => ({
            product: item.name,
            product_id: item.name,
            mesure: item.mesure,
            price: item.regular_price,
            quantity: Number(quantities[item.id]),
            total_price: item.regular_price * quantities[item.id]
          }));
        const finalDict = {
          ...form,
          order,
          order_n_products: Object.values(quantities).reduce((a, b) => Number(a) + Number(b), 0),
          order_price: totalPrice,
          special_demand: specialDemands,
          timestamp: Date.now(),
        };
        // send finalDict to server here
        const response = await fetch(ORDERS_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(finalDict),
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log(`Order ${data.message}`);
        setShowThankYou(true)

      }
      catch (error) {
        console.error('Error:', error);
      }
      finally {
        setIsSubmitting(false);
      }
    }
  };

  return (

    <div className="App">
      <Header />
      <main>
        {showThankYou ? (<ThankYou />) : (<>
          <SplashAndText />
          <Instructions />
          <ThreeSteps />
          <ProductListBanner />
          <SearchItem search={search} setSearch={setSearch} />

          <ButtonList tab={tab} setTab={setTab} />

          {isLoading && <p className="error" style={{ color: "red" }}> Loading</p>}
          {fetchError && <p className="error" style={{ color: "red" }}>{fetchError}</p>}
          {!fetchError && !isLoading && <Content
            items={items
              .filter(item => (item.tab === tab || tab === "Tous les produits"))
              .filter(item => ((item.name).toLowerCase()).includes(search.toLowerCase()))
            }
            quantities={quantities} setQuantities={setQuantities} />}
          {
            Object.values(quantities).some(quantity => quantity > 0)
              ? <>
                <div className="form-border">
                  <p style={{
                    color: "#BCA371",
                    fontWeight: 700,
                    fontSize: "medium",
                    marginTop: "1em",
                    marginLeft: "1em",
                    marginBottom: "2em",
                  }}> Information du panier</p>
                  <TotalCart items={items} quantities={quantities} totalPrice={totalPrice} />
                  <SpecialDemands specialDemands={specialDemands} setSpecialDemands={setSpecialDemands} />
                </div>
                <div className="form-border">
                  <p style={{
                    color: "#BCA371",
                    fontWeight: 700,
                    fontSize: "medium",
                    marginTop: "1em",
                    marginLeft: "1em"
                  }}> Instruction de livraison</p>
                  <DeliveryOptions setForm={setForm} handleSubmit={handleSubmit} formRef={formRef} />
                </div>
                <p style={{
                  fontSize: ".75rem",
                  margin: "0.25em",
                  marginTop: "1.25em",
                  marginBottom: "1.25em",
                  lineHeight: 1.75,
                  color: "#62615C",
                  textAlign: "justify",
                }}>Il ne vous reste plus qu'à cliquer sur le bouton ci-dessous et votre commande sera transmise à notre vendeur local qui s'occupera de votre livraison. Le paiement sera effectué à la livraison directement en espèces ou en Twint ou après la livraison par Twint.</p>
                <button onClick={handleClick} type="submit" disabled={isSubmitting} className="form-container submit-button">
                  {isSubmitting ? "En cours de traitement..." : "Envoyer la commande"}
                </button>
              </>
              : null}
        </>)}
      </main>
      {
        !showThankYou && Object.values(quantities).some(quantity => quantity > 0)
          ? <Footer totalPrice={totalPrice} />
          : null
      }
    </div >
  );
}

export default App;
