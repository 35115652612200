import React from 'react';

const Footer = ({ totalPrice }) => {
    return (
        <footer>
            <p>Total de la commande: {totalPrice} CHF</p>
        </footer>
    );
};

export default Footer;
