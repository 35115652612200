import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';

const ThreeSteps = () => {
    return (
        <div className='threesteps_container'>
            <div className='threesteps_title'>
                <p>3 étapes simples pour recevoir des produits à la maison:​</p>
            </div>
            <div className='threesteps_container'>
                <div className='threesteps_container_step'>
                    <div className='threesteps_container_icon'><FontAwesomeIcon icon={faCircleArrowRight} /></div>
                    <div> Choisissez ce dont vous avez besoin dans la liste ci-dessous​</div>
                </div>
                <div className='threesteps_container_step'>
                    <div className='threesteps_container_icon'><FontAwesomeIcon icon={faCircleArrowRight} /></div>
                    <div> Saisissez vos coordonnées de livraison</div>
                </div>
                <div className='threesteps_container_step'>
                    <div className='threesteps_container_icon'><FontAwesomeIcon icon={faCircleArrowRight} /></div>
                    <div> Recevez vos beaux produits et payez le vendeur directement (Twint ou cash)</div>
                </div>
            </div>
        </div>
    );
};

export default ThreeSteps;
