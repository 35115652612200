import ItemList from "./ItemList";

const Content = ({ items, quantities, setQuantities }) => {
    return (
        <>
            {items.length ? (<ItemList
                items={items} quantities={quantities} setQuantities={setQuantities}
            />) : (<p className="empty">No items in the list</p>)}
        </>
    );
};

export default Content;
