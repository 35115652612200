import React from 'react';

const DeliveryOptions = ({ setForm, handleSubmit, formRef }) => {
    const handleChange = (e) => {
        const { name, value } = e.target;
        const [section, key] = name.split('.');
        setForm(prevForm => ({
            ...prevForm,
            [section]: {
                ...prevForm[section],
                [key]: value,
            },
        }));
    };


    return (
        <section className='form-container'>
            <form ref={formRef} onSubmit={handleSubmit}>
                <p className='form-title'>Jour et période de livraison gratuite.</p>

                <input required onChange={handleChange} type="radio" name='delivery.schedule' id="mardi_matin" value="Mardi matin suivant (vers 4h30, avant l'heure de début du marché)" />
                <label className='form-text' htmlFor="mardi_matin">Mardi matin suivant (vers 4h30, avant l'heure de début du marché)</label><br />
                <input onChange={handleChange} type="radio" name='delivery.schedule' id="mardi_prochain" value="Mardi prochain à la mi-journée" />
                <label className='form-text' htmlFor="mardi_prochain">Mardi prochain à la mi-journée</label><br />
                <input onChange={handleChange} type="radio" name='delivery.schedule' id="jeudi" value="Jeudi matin suivant (entre 7h00 et 8h30)" />
                <label className='form-text' htmlFor="jeudi">Jeudi matin suivant (entre 7h00 et 8h30)</label><br />

                <p className='form-title'>Informations sur le lieu de livraison</p>
                <p className='form-text'>Veuilliz indiquer ci-dessous comment vous préférez recevoir votre commande</p>
                <input required onChange={handleChange} type="radio" name='delivery.instruction' id="boite" value="En haut de la boîte aux lettres" />
                <label className='form-text' htmlFor="boite">En haut de la boîte aux lettres</label><br />
                <input onChange={handleChange} type="radio" name='delivery.instruction' id="exterieur" value="A côté de la porte d'entrée (à l'extérieur)" />
                <label className='form-text' htmlFor="exterieur">A côté de la porte d'entrée (à l'extérieur)</label><br />
                <input onChange={handleChange} type="radio" name='delivery.instruction' id="interieur" value="A côté de la porte d'entrée (à l'intérieur)" />
                <label className='form-text' htmlFor="interieur">A côté de la porte d'entrée (à l'intérieur)</label><br />
                <input onChange={handleChange} type="radio" name='delivery.instruction' id="interphone" value="Sonnez à l'interphone" />
                <label className='form-text' htmlFor="interphone">Sonnez à l'interphone</label><br />
                <input onChange={handleChange} type="radio" name='delivery.instruction' id="autre" value="Autre (indiquer ci-dessous)" />
                <label onChange={handleChange} className='form-text' htmlFor="autre">Autre (indiquer ci-dessous)</label><br />
                <textarea onChange={handleChange} rows="1" name="delivery.free_text" />

                <p className='form-title'>Coordonnées du client</p>
                <input required onChange={handleChange} type="text" name="client.first_name" placeholder="Prénom" /><br />
                <input required onChange={handleChange} type="text" name="client.last_name" placeholder="Nom" /><br />
                <input required onChange={handleChange} type="text" name="client.phone" placeholder="Téléphone" /><br />
                <input required onChange={handleChange} type="text" name="client.email" placeholder="email" /><br />
                <input required onChange={handleChange} type="text" name="client.address" placeholder="Adresse" /><br />
                <input required onChange={handleChange} type="text" name="client.city" placeholder="Ville" /><br />
                <input required onChange={handleChange} type="text" name="client.postal_code" placeholder="Code postal" /><br />
                <input onChange={handleChange} type="text" name="client.state" placeholder="Région" value="Fribourg" readOnly /><br />
                <input onChange={handleChange} type="text" name="client.country" placeholder="Pays" value="Suisse" readOnly /><br />
            </form>
        </section >
    );
};

export default DeliveryOptions;
