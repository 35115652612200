
import React from 'react';

const ThankYou = () => {
    return (
        <div className='thank-you'>

            <p className='thank-you-title'>Merci pour votre commande!</p>

            <p className='thank-you-text'>Votre achat est en cours de préparation par nos vendeurs locaux.
                Bientôt, des produits suisses de qualité seront chez vous.
            </p>


            <p className='thank-you-text' style={{ textAlign: "center" }}>
                Des questions ? Contactez-nous : <a href="mailto:marchelocal.site@gmail.com"> marchelocal.site@gmail.com</a></p>

            <p className='thank-you-text' style={{ textAlign: "center" }}>Merci de soutenir notre communauté. </p>
            <img
                src="https://storage.googleapis.com/marchelocal.appspot.com/site-images/cropped-Canva-Logo-Legumes-Amarelo-sem-texto-120x89.png"
                alt="logo"
                style={{ height: 50, display: "flex", justifyContent: "center", margin: "auto" }}
            />
            <p className='header-subtitle' style={{ textAlign: "center" }}>Les produits locaux à votre portée de main. </p>
        </div>
    );
};

export default ThankYou;
