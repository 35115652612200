import React from 'react';
import LineItem from './LineItem';

const ItemList = ({ items, quantities, setQuantities }) => {
    return (
        <ul>
            {items.map((item) => (
                <LineItem
                    key={item.id}
                    item={item}
                    quantities={quantities}
                    setQuantities={setQuantities}
                />
            ))}
        </ul>
    );
};

export default ItemList;
