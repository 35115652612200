import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBox, faTag, faPerson, faPersonDress, faSeedling } from '@fortawesome/free-solid-svg-icons';

const Instructions = () => {
    return (
        <div className="instructions-container">
            <div className="text-box">
                <div className="text-box-item-icon">
                    <FontAwesomeIcon icon={faBox} />
                </div>
                <div className="text-box-item-header">
                    <h5>Livraison gratuite</h5>
                </div>
                <div className="text-box-horizontal-bar"></div>
                <div className="text-box-item-text">
                    <p>Mardi et Jeudi</p>
                </div>
            </div>

            <div className="text-box">
                <div className="text-box-item-icon">
                    <FontAwesomeIcon icon={faTag} />
                </div>
                <div className="text-box-item-header">
                    <h5>Prix bon marché</h5>
                </div>
                <div className="text-box-horizontal-bar"></div>
                <div className="text-box-item-text">
                    <p>Sans les grands intermédiaires</p>
                </div>
            </div>

            <div className="text-box">
                <div className="text-box-item-icon">
                    <FontAwesomeIcon icon={faPerson} />
                    <FontAwesomeIcon icon={faPersonDress} />
                </div>
                <div className="text-box-item-header">
                    <h5>Favorise la communauté</h5>
                </div>
                <div className="text-box-horizontal-bar"></div>
                <div className="text-box-item-text">
                    <p>Ventes locales</p>
                </div>
            </div>
            <div className="text-box">
                <div className="text-box-item-icon">
                    <FontAwesomeIcon icon={faSeedling} />
                </div>
                <div className="text-box-item-header">
                    <h5>Écologique et durable</h5>
                </div>
                <div className="text-box-horizontal-bar"></div>
                <div className="text-box-item-text">
                    <p>Produits locaux à basses émissions de CO2</p>
                </div>
            </div>
        </div >
    );
};

export default Instructions;
