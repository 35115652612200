const Header = () => {
    return (
        <header>
            <img
                src="https://storage.googleapis.com/marchelocal.appspot.com/site-images/cropped-Canva-Logo-Legumes-Amarelo-sem-texto-120x89.png"
                alt="logo"
                className="header-logo"
            />
            <p className="header-title">Marché Local</p>
            <p className="header-subtitle">Les produits locaux à portée de main</p>
        </header>
    );
}

export default Header;
