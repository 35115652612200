import React from 'react';

const SpecialDemands = ({ specialDemands, setSpecialDemands }) => {

    const handleChange = (e) => {
        setSpecialDemands(e.target.value);
    };

    return (
        <section className='form-container'>
            <form >
                <p className='form-title'>Ajouter une note pour des intructions et demandes spéciales.</p>
                <p className='form-text'>Il y a possibilité de faire des demandes pour des produits ne figurants pas sur la liste et nous ferons de notre mieux pour y satisfaire.</p>
                <textarea value={specialDemands} onChange={handleChange} rows="3" name="delivery.special_demand" placeholder='Ajoutez des instructions ou des demandes spéciales ici' />
            </form>
        </section >
    );
};

export default SpecialDemands;
