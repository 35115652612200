import React from 'react';
import { options } from './LineItem';

const TotalCart = ({ items, quantities, totalPrice }) => {
    console.log(quantities)
    console.log(items)
    console.log(totalPrice)
    console.log(options)
    return (
        <>
            <p className='panier-title'>Total panier</p>

            <table>
                <thead>
                    <tr>
                        <th>Produit</th>
                        <th>Prix (CHF)</th>
                        <th>Quantité</th>
                        <th>Total (CHF)</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(quantities).map(key => {
                        const product = items.find(p => p.id === Number(key));
                        return product ? (
                            <tr key={key}>
                                <td>{product.name}</td>
                                <td>{parseFloat(product.regular_price).toFixed(2)}</td>
                                <td>{options[product.mesure][quantities[key]]} {product.mesure}(s)</td>
                                <td>{parseFloat(product.regular_price * quantities[key]).toFixed(2)}</td>
                            </tr>
                        ) : null;
                    })}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan="3">Valeur totale des articles </td>
                        <td>{parseFloat(totalPrice).toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td colSpan="3">Livraison </td>
                        <td>{parseFloat(0).toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td colSpan="3">Taxes </td>
                        <td>{parseFloat(0).toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td colSpan="3">Total commande </td>
                        <td>{parseFloat(totalPrice).toFixed(2)}</td>
                    </tr>
                </tfoot>
            </table>
        </>
    );
};

export default TotalCart;