import React from 'react';
import Button from './Button';

const ButtonList = ({ tab, setTab }) => {
    return (
        <div className="button-array">
            <Button tab={tab} setTab={setTab} buttonLabel="Tous les produits" />
            <Button tab={tab} setTab={setTab} buttonLabel="Légumes-racines" />
            <Button tab={tab} setTab={setTab} buttonLabel="Fruits" />
            <Button tab={tab} setTab={setTab} buttonLabel="Salades" />
            <Button tab={tab} setTab={setTab} buttonLabel="Herbes" />
            <Button tab={tab} setTab={setTab} buttonLabel="Artisanaux" />
        </div>
    );
};

export default ButtonList;
